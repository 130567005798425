<template>
  <div class="about__container">
    <div class="about__lnb">
      <ul>
        <li>
          <button
            type="button"
            @click="$emit('movePage', '/introduction/function01')"
          >
            디자인 관리
          </button>
        </li>
        <li>
          <button
            type="button"
            @click="$emit('movePage', '/introduction/function02')"
          >
            반응형 지원
          </button>
        </li>
        <li class="active">
          <button
            type="button"
            @click="$emit('movePage', '/introduction/function03')"
          >
            솔루션 연결
          </button>
        </li>
      </ul>
    </div>
    <div class="about__visual about__visual--solution">
      <dl>
        <dt>솔루션 연결</dt>
        <dd>
          비즈니스에 맞는 솔루션과 연동하여<br />바로 쇼핑몰 운영을 시작하세요.
        </dd>
      </dl>
    </div>
    <div class="about__function">
      <p>솔루션 연동, 쉽고 빠르게</p>
      <article>
        <div class="function__swiper">
          <swiper
            ref="functionSwiper"
            :options="functionSwiperOption"
            @slideChange="onSlideChange"
          >
            <swiper-slide>
              <img src="~@/assets/images/intro/img_about_solution01.png" />
              <p :class="{active: isTab == 1}">
                Step1. 마이소호에서 큐픽 플러그인 설치
              </p>
            </swiper-slide>
            <swiper-slide>
              <img src="~@/assets/images/intro/img_about_solution02.png" />
              <p :class="{active: isTab == 2}">Step2. 큐픽 연동하기 클릭</p>
            </swiper-slide>
            <swiper-slide>
              <img src="~@/assets/images/intro/img_about_solution03.png" />
              <p :class="{active: isTab == 3}">
                Step3. 연동 회원 유형 선택으로 연동 끝!
              </p>
            </swiper-slide>
          </swiper>
        </div>
        <dl>
          <dt>마이소호</dt>
          <dd>
            마이소호는 초기 창업자의 사용에 최적화되어 누구나 쉽게 사용할 수
            있고 다양하게 상품을 판매할 수 있는 강력한 기능을 제공합니다.
            <button
              class="lnk__solution ga_f_24"
              @click="$emit('movePage', 'mysoho')"
            >
              <span>마이소호 바로가기</span>
            </button>
          </dd>
        </dl>
      </article>
    </div>
    <div class="about__other">
      <article>
        <p><span>다른 내용이</span> 더 궁금하신가요?</p>
        <ul>
          <li @click="$emit('movePage', '/introduction/function01')">
            <dl class="about__other--design">
              <dt>디자인 관리</dt>
              <dd>
                트렌디한 테마와 컴포넌트 디자인으로<br />내 쇼핑몰 디자인을
                완성하세요.
              </dd>
            </dl>
          </li>
          <li @click="$emit('movePage', '/introduction/function02')">
            <dl class="about__other--responsive">
              <dt>반응형 지원</dt>
              <dd>
                PC, 모바일을 유연하게 대응하는<br />반응형 쇼핑몰을 쉽게
                제작해보세요.
              </dd>
            </dl>
          </li>
        </ul>
      </article>
    </div>
    <section class="about_start about_start--solution" v-show="!getLoginStatus">
      <article>
        <p>
          고민없는 쇼핑몰 제작<br />
          큐픽으로 시작하세요.
        </p>
        <button
          class="lnk__start--dark ga_f_25"
          @click="$emit('movePage', '/accounts/signup')"
        >
          무료 시작하기
        </button>
      </article>
    </section>
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";
import {Swiper, SwiperSlide} from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import "@/assets/scss/intro.scss";

const ModuleAuth = createNamespacedHelpers("ModuleAuth");

export default {
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      isTab: 1,
      functionSwiperOption: {
        observer: true,
        observeParents: true,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false
        },
        effect: "fade"
      }
    };
  },
  computed: {
    ...ModuleAuth.mapGetters(["getLoginStatus"])
  },
  methods: {
    onSlideChange() {
      this.isTab = this.$refs.functionSwiper.$swiper.realIndex + 1;
    }
  },
  mounted() {},
  beforeDestroy() {}
};
</script>

<style lang="scss" scoped>
div,
button {
  font-family: "Pretendard", "Malgun Gothic", sans-serif;
  color: #1c1e23;
  line-height: 1.25;
}
.about__function {
  > p {
    font-size: 48px;
    font-weight: bold;
    text-align: center;
  }
  article {
    dd {
      margin-top: 16px;
    }
  }
}
.function__swiper {
  position: absolute;
  top: 0;
  left: 0;
  width: 488px;
  height: 432px;
  background-color: #eff2f8;
  border-radius: 16px;

  .swiper-container {
    margin-top: 40px;
  }
  .swiper-slide {
    text-align: center;
  }
  p {
    display: none;
    margin-top: 24px;
    color: #676b74;
    font-size: 18px;
    text-align: center;
  }
  p.active {
    display: block;
  }
}
@media (max-width: 1023px) {
  .about__function {
    > p {
      font-size: 28px;
    }
    article {
      margin-top: 40px;
      padding-left: 460px;
    }
  }
  .function__swiper {
    width: 420px;
    height: 324px;

    .swiper-container {
      margin-top: 30px;
    }
    .swiper-slide img {
      width: 350px;
    }
    p {
      margin-top: 10px;
      font-size: 15px;
    }
  }
}
@media (max-width: 767px) {
  .about__function {
    > p {
      padding: 0 16px;
      text-align: left;
    }
    article {
      padding: 0 16px;

      dl {
        margin-top: 24px;
      }
    }
  }
  .function__swiper {
    position: relative;
    width: 100%;
    height: 288px;

    .swiper-slide img {
      width: 288px;
    }
  }
}
</style>
