var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "about__container"
  }, [_c('div', {
    staticClass: "about__lnb"
  }, [_c('ul', [_c('li', [_c('button', {
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('movePage', '/introduction/function01');
      }
    }
  }, [_vm._v(" 디자인 관리 ")])]), _c('li', [_c('button', {
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('movePage', '/introduction/function02');
      }
    }
  }, [_vm._v(" 반응형 지원 ")])]), _c('li', {
    staticClass: "active"
  }, [_c('button', {
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('movePage', '/introduction/function03');
      }
    }
  }, [_vm._v(" 솔루션 연결 ")])])])]), _vm._m(0), _c('div', {
    staticClass: "about__function"
  }, [_c('p', [_vm._v("솔루션 연동, 쉽고 빠르게")]), _c('article', [_c('div', {
    staticClass: "function__swiper"
  }, [_c('swiper', {
    ref: "functionSwiper",
    attrs: {
      "options": _vm.functionSwiperOption
    },
    on: {
      "slideChange": _vm.onSlideChange
    }
  }, [_c('swiper-slide', [_c('img', {
    attrs: {
      "src": require("@/assets/images/intro/img_about_solution01.png")
    }
  }), _c('p', {
    class: {
      active: _vm.isTab == 1
    }
  }, [_vm._v(" Step1. 마이소호에서 큐픽 플러그인 설치 ")])]), _c('swiper-slide', [_c('img', {
    attrs: {
      "src": require("@/assets/images/intro/img_about_solution02.png")
    }
  }), _c('p', {
    class: {
      active: _vm.isTab == 2
    }
  }, [_vm._v("Step2. 큐픽 연동하기 클릭")])]), _c('swiper-slide', [_c('img', {
    attrs: {
      "src": require("@/assets/images/intro/img_about_solution03.png")
    }
  }), _c('p', {
    class: {
      active: _vm.isTab == 3
    }
  }, [_vm._v(" Step3. 연동 회원 유형 선택으로 연동 끝! ")])])], 1)], 1), _c('dl', [_c('dt', [_vm._v("마이소호")]), _c('dd', [_vm._v(" 마이소호는 초기 창업자의 사용에 최적화되어 누구나 쉽게 사용할 수 있고 다양하게 상품을 판매할 수 있는 강력한 기능을 제공합니다. "), _c('button', {
    staticClass: "lnk__solution ga_f_24",
    on: {
      "click": function click($event) {
        return _vm.$emit('movePage', 'mysoho');
      }
    }
  }, [_c('span', [_vm._v("마이소호 바로가기")])])])])])]), _c('div', {
    staticClass: "about__other"
  }, [_c('article', [_vm._m(1), _c('ul', [_c('li', {
    on: {
      "click": function click($event) {
        return _vm.$emit('movePage', '/introduction/function01');
      }
    }
  }, [_vm._m(2)]), _c('li', {
    on: {
      "click": function click($event) {
        return _vm.$emit('movePage', '/introduction/function02');
      }
    }
  }, [_vm._m(3)])])])]), _c('section', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.getLoginStatus,
      expression: "!getLoginStatus"
    }],
    staticClass: "about_start about_start--solution"
  }, [_c('article', [_vm._m(4), _c('button', {
    staticClass: "lnk__start--dark ga_f_25",
    on: {
      "click": function click($event) {
        return _vm.$emit('movePage', '/accounts/signup');
      }
    }
  }, [_vm._v(" 무료 시작하기 ")])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "about__visual about__visual--solution"
  }, [_c('dl', [_c('dt', [_vm._v("솔루션 연결")]), _c('dd', [_vm._v(" 비즈니스에 맞는 솔루션과 연동하여"), _c('br'), _vm._v("바로 쇼핑몰 운영을 시작하세요. ")])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('span', [_vm._v("다른 내용이")]), _vm._v(" 더 궁금하신가요?")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('dl', {
    staticClass: "about__other--design"
  }, [_c('dt', [_vm._v("디자인 관리")]), _c('dd', [_vm._v(" 트렌디한 테마와 컴포넌트 디자인으로"), _c('br'), _vm._v("내 쇼핑몰 디자인을 완성하세요. ")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('dl', {
    staticClass: "about__other--responsive"
  }, [_c('dt', [_vm._v("반응형 지원")]), _c('dd', [_vm._v(" PC, 모바일을 유연하게 대응하는"), _c('br'), _vm._v("반응형 쇼핑몰을 쉽게 제작해보세요. ")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_vm._v(" 고민없는 쇼핑몰 제작"), _c('br'), _vm._v(" 큐픽으로 시작하세요. ")]);
}]

export { render, staticRenderFns }